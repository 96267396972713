<template>
    <div class="page-container">
         <!-- <p class="main-title" >{{$route.query.main_title}}<a @click="reload" style="margin-left:20px;font-size:14px;font-weight:100;"><i class="el-icon-refresh-right"></i></a></p>  -->
        <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>
        <div class="search-form">
            <el-form ref="form" :inline="true"  :model="search_key" prop="">
                <el-form-item label="操作人员">
                    <el-select v-model="search_key.admin_id" placeholder="请选择">
                        <el-option label="请选择"  value=""></el-option>
                        <el-option v-for="(item,index) in adminList" :key="index" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                 <el-form-item label="类型">
                    <el-select v-model="search_key.type" placeholder="请选择">
                        <el-option label="请选择"  value=""></el-option>
                        <el-option v-for="(item,index) in typeList" :key="index" :label="item" :value="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-button type="primary" @click="getData()">查询</el-button>
                <el-button type="primary" @click="dialogExportVisible = true" style="position:absolute;right:30px;">导出</el-button>
            </el-form>
         </div>
        <el-table
			v-loading="loading"
			element-loading-text="查询中"
            :data="tableData" 
            border
            style="width: 100%">
            <el-table-column
               fixed
               prop="id"
               label="ID">
            </el-table-column>
             <el-table-column
               fixed
               label="操作人员">
               <template slot-scope="scope">
                   <span>[{{scope.row.admin_nickname}}][{{scope.row.admin_name}}]</span>
               </template>
            </el-table-column>
            <el-table-column
               fixed
               prop="name"
               label="操作描述">
            </el-table-column>
            <el-table-column
               fixed
               prop="type_text"
               label="类型">
            </el-table-column>
             <el-table-column
               fixed
               prop="create_time"
               label="操作时间">
            </el-table-column>
           <el-table-column
            fixed="right"
            label="操作">
            <template slot-scope="scope">
                <el-button  size="small" @click="handleShow(scope.row)">查看详情</el-button>
            </template>
            </el-table-column>
         </el-table>
         <pagination-view :table-data-total='total' @currPage='getCurrPage' @pageSize='getPageSize'></pagination-view>
         <el-dialog title="" :visible.sync="dialogAddVisible">
            <el-descriptions title="操作人" :column="3">
                <el-descriptions-item label="ID">{{detail.admin_id}}</el-descriptions-item>
                <el-descriptions-item label="账号">{{detail.admin_nickname}}</el-descriptions-item>
                <el-descriptions-item label="姓名">{{detail.admin_name}}</el-descriptions-item>
                <el-descriptions-item label="手机号">{{detail.mobile}}</el-descriptions-item>
                <el-descriptions-item label="状态">{{detail.status==1?'在职':'离职'}}</el-descriptions-item>
            </el-descriptions>  
            <el-descriptions title="操作内容" :column="3">
                <el-descriptions-item label="ID">{{detail.id}}</el-descriptions-item>
                <el-descriptions-item label="时间">{{detail.create_time}}</el-descriptions-item>
                <el-descriptions-item label="描述">{{detail.name}}</el-descriptions-item>
                <el-descriptions-item label="类型">{{detail.type_text}}</el-descriptions-item>
            </el-descriptions> 
            <el-descriptions title="内容" :column="1">
               <el-descriptions-item  v-for="(item,index) in detail.content" :key="index" :label="index">{{item}}</el-descriptions-item>
            </el-descriptions>
         </el-dialog>

         <!-- 导出弹窗 -->
         <el-dialog title="导出筛选" :visible.sync="dialogExportVisible">
             <el-form ref='export_form' :model="export_form" prop="">
                <el-form-item label="选择类型" prop="">
                    <el-select v-model="export_form.type" placeholder="请选择">
                        <el-option label="请选择"  value=""></el-option>
                        <el-option v-for="(item,index) in typeList" :key="index" :label="item" :value="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="日期范围" prop="date">
                    <el-date-picker
                        v-model="export_form.create_time"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss">
                    </el-date-picker>
                </el-form-item>
             </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="confirmExport()">导出</el-button>
            </div>
         </el-dialog>
    </div>
</template>
<script>
// import { param } from '../../../xavue/public/ueditor/third-party/jquery-1.10.2';

export default {
    inject:['reload'],
   data() {
      return {
		loading:true,
        tableData:[],
        currentPage:1,
        pagesize:10,
        total:0,
        adminList:[],
        typeList:[],
        search_key:{
            admin_id:'',
            type:''
        },
        dialogAddVisible:false,
        detail:'',
        dialogExportVisible:false,
        export_form:{
            create_time:'',
            type:''
        }
      }
   },
   created(){
       this.getData();
   },
   mounted(){
   },
   methods:{
       getData(){
           var arr = []
           arr['page'] = this.currentPage
           arr['limit'] = this.pagesize
           arr['admin_id'] = this.search_key.admin_id
           arr['type'] = this.search_key.type
           var params = {...arr}
           this.$api.post('SysLog/index',params,res=>{
               if (res.code == 200) {
                   this.tableData =  res.data.list 
                   this.total =  res.data.count
                   this.adminList =  res.data.admin_list
                   this.typeList  = res.data.type_list
               }else{
				   this.$meesage.err(res.msg)
			   }
			   this.loading = false
           })
       },
    //    search(){
    //         this.$api.post('SysLog/index',{admin_id:this.search_key.admin_id,type:this.search_key.type},res=>{
    //            if (res.code == 200) {
    //                this.tableData =  res.data.list
    //                this.total =  res.data.count
    //            }
    //        })
    //    },
       handleShow(row){
           this.dialogAddVisible =  true
           this.$api.get('SysLog/show',{id:row.id},res=>{
               if (res.code == 200) {
                   this.detail =  res.data
               }
           })
       },
        confirmExport(){    //导出
            this.$api.post('SysLog/export',null,res=>{
                if (res.code == 200) {
                    location.href = '/api/admin/SysLog/export?type='+this.export_form.type+'&create_time='+this.export_form.create_time
                     // this.dialogExportVisible =  false
                }else{ 
                    this.$meesage.error(res.msg)
                }
               
            })
        },
        getCurrPage(val){   //获取当前页
            this.currentPage =  val
			this.loading = true
            this.getData()
        },
        getPageSize(val){   //获取每页条数
            this.pagesize =  val
            this.getData()
        },
   }

}
</script>
<style scoped>
</style>